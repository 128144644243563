<template>
	<div class="w-full dF fC f1 pb-4 hide-scrollbar" style="overflow-y:scroll">
		<bhLoading :show="loading" />
		<a-card title="Custom Fields">
			<a-table :rowKey="(e) => e.id" class="white-table" :columns="columns" :data-source="customFields">
				<div slot="default" slot-scope="obj">
					<div class="dF" v-if="typeof obj.defaultAnswer == 'object'">
						<div class="px-2" :class="ansI != obj.defaultAnswer.length - 1 ? 'mr-3' : ''"
							style="background-color:var(--off-white-dark); border:1px solid #000"
							v-for="(ans, ansI) in obj.defaultAnswer" :key="ans + ansI">{{ ans }}</div>
					</div>
					<div v-else>{{ obj.defaultAnswer }}</div>
				</div>
				<div slot="date" slot-scope="obj" style="color:#9EA0A5">{{ obj.date ? convertDate(obj.date) : '' }}</div>
				<div slot="user" slot-scope="obj">{{ obj.user && obj.user.firstName && obj.user.lastName &&
					obj.user.firstName != '' && obj.user.lastName != '' ? `${obj.user.firstName} ${obj.user.lastName}` : '' }}</div>
				<div slot="type" slot-scope="obj">{{ obj.type == 'text' ? 'Text' : 'Multiple Choice' }}</div>
				<div slot="action" slot-scope="obj" class="dF">
					<div @click="editField(obj.id)" class="mr-3" style="cursor:pointer"><svg
							xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none"
							stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
							class="feather feather-edit-2">
							<path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
						</svg></div>
					<div @click="deleteField(obj)" style="color:#FD647C; cursor:pointer"><svg
							xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none"
							stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
							class="feather feather-trash-2">
							<polyline points="3 6 5 6 21 6"></polyline>
							<path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
							<line x1="10" y1="11" x2="10" y2="17"></line>
							<line x1="14" y1="11" x2="14" y2="17"></line>
						</svg></div>
				</div>
			</a-table>
			<div @click="addField" style="padding-top:24px; cursor:pointer; color:var(--orange); display:inline-block">
				<div class="dF aC">
					<a-icon style="font-size:20px" class="mr-3" type="plus-circle" />
					<div>ADD CUSTOM FIELD</div>
				</div>
			</div>
		</a-card>
	</div>
</template>

<script>
import bhLoading from 'bh-mod/components/common/Loading'
export default {
	components: { bhLoading },
	computed: {
		dateFormat() {
			return this.$store.state.models.allSettings.user && this.$store.state.models.allSettings.user.options && this.$store.state.models.allSettings.user.options.regional && this.$store.state.models.allSettings.user.options.regional.dateFormat ? this.$store.state.models.allSettings.user.options.regional.dateFormat : 'MM/DD/YYYY'
		},
		customFields() {
			if (this.$store.state.models.allSettings && this.$store.state.models.allSettings.app && this.$store.state.models.allSettings.app.options && this.$store.state.models.allSettings.app.options.customFields) return this.$store.state.models.allSettings.app.options.customFields
			return [];
		}
	},
	data() {
		return {
			loading: false,
			columns: [
				{
					dataIndex: "name",
					key: "name",
					title: 'Name'
				},
				{
					title: 'Field Type',
					key: 'type',
					slots: { title: 'Field Type' },
					scopedSlots: { customRender: 'type' }
				},
				{
					title: 'Default Value',
					key: 'default',
					slots: { title: 'Default Value' },
					scopedSlots: { customRender: 'default' }
				},
				{
					title: 'Date Create',
					key: 'date',
					slots: { title: 'Date Create' },
					scopedSlots: { customRender: 'date' }
				},
				{
					title: 'Modified By',
					key: 'user',
					slots: { title: 'Modified By' },
					scopedSlots: { customRender: 'user' }
				},
				{
					title: 'Actions',
					key: 'action',
					slots: { title: 'Actions' },
					scopedSlots: { customRender: 'action' }
				}
			],
			options: []
		}
	},
	methods: {
		req: msg => ({ required: true, message: msg }),

		convertDate(num) {
			let x = new Date(num)
			//Tue Oct 06 2020 12:11:07 GMT-0400 (Eastern Daylight Time)
			let year = x.getFullYear()
			let month = x.getMonth() + 1
			let day = x.getDate()
			if (this.dateFormat == 'YYYY/MM/DD') return year + '/' + month + '/' + day
			else if (this.dateFormat == 'DD/MM/YYYY') return day + '/' + month + '/' + year
			else {
				return month + '/' + day + '/' + year
			}
		},

		addField() {
			this.$store.commit('ADD_FIELD')
		},

		editField(id) {
			this.$store.commit('EDIT_FIELD', id)
		},

		deleteField(obj) {
			let self = this
			if (this.$p < 20) return this.$message.error('You do not have permission to perform this action')
			this.$confirm({
				title: "Delete Custom Field",
				content: h => <div>Do you want to delete this Custom Field?</div>,
				okText: 'Delete',
				okType: 'danger',
				cancelText: 'Cancel',
				centered: true,
				onOk() {
					let fields = JSON.parse(JSON.stringify(self.customFields))
					let index = fields.findIndex(x => x.id == obj.id)
					fields.splice(index, 1)
					self.loading = true
					self.$api.put(`/settings/:instance/models`, { options: { customFields: fields } }).then(({ data }) => {
						self.$store.commit('UPDATE_SETTINGS', data)
						self.loading = false
					}).catch(err => {
						self.loading = false
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							self.$message.error(self.$err(err))
						}
					})
				}
			})
		},
	}
}
</script>

<style>
</style>
