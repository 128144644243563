<template>
    <div class="w-full dF fC f1 pb-4 hide-scrollbar" style="overflow-y:scroll">
        <bhLoading :show="loading" />
        <a-card title="Publishing Assets">
            <a-form>
                <a-row :gutter="32">
                    <a-col :span="12">
                        <a-form-item label="Agreement of Purchase/Lease (PDF)">
                            <p class="mt-0 mb-2 text-med-gray" style="line-height:1.1;">This will be shown as a downloadable in your published media alongside your models.</p>
                            <ImageBoxSelector :removable="true" type="pdf" :key="publishing.aps" :value="publishing.aps" @input="(e) => updateAps(e)" />
                        </a-form-item>
                    </a-col>
                    <!-- <a-col :span="12">
                        <a-form-item label="Builder Logo">
                            <p class="mt-0 mb-2 text-med-gray" style="line-height:1.1;">This logo will be used to distinguish in collaborative published projects.</p>
                            <ImageBoxSelector :removable="true" :key="publishing.builderLogo" v-model="publishing.builderLogo" />
                        </a-form-item>
                    </a-col> -->
					<a-col :span="12">
                        <a-form-item label="Price List (PDF)">
                            <p class="mt-0 mb-2 text-med-gray" style="line-height:1.1;">This will be shown as a downloadable in your published media alongside your models.</p>
                            <ImageBoxSelector :removable="true" type="pdf" :key="publishing.priceList" :value="publishing.priceList" @input="(e) => updatePriceList(e)" />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="24">
                        <a-form-item label="Legal Disclaimer">
                            <p class="mt-0 mb-2 text-med-gray" style="line-height:1.1;">This will be shown alongside your models in your published projects.</p>
                            <a-input v-model="publishing.legalDisclaimer" type="textarea" rows="4" />
                        </a-form-item>
                    </a-col>
                </a-row>

            </a-form>
            <hr />
            <div class="dF jE">
                <a-button type="secondary" @click="cancel" size="large" class="cancel-button">RESET</a-button>
                <a-button type="primary" @click="save" class="ml-3" size="large">SAVE</a-button>
            </div>
        </a-card>
    </div>
</template>

<script>
import ImageBoxSelector from 'bh-mod/components/common/ImageBoxSelector'
import bhLoading from 'bh-mod/components/common/Loading'
export default {
    components: {
        ImageBoxSelector,bhLoading
    },
    computed:{
       original(){
            if (this.$store.state.models.allSettings.app && this.$store.state.models.allSettings.app.options && this.$store.state.models.allSettings.app.options.publishing && typeof this.$store.state.models.allSettings.app.options.publishing === 'object'){
                return this.$store.state.models.allSettings.app.options.publishing
            }
            return {
                aps: '',
                builderLogo: '',
                legalDisclaimer: '',
				priceList:''
            }
       }
    },
    watch:{
        original:{
            immediate:true,
            handler(val){
                this.publishing = JSON.parse(JSON.stringify(val))
            }
        }
    },
    data() {
        return {

            publishing: {
                aps: '',
                builderLogo:'',
                legalDisclaimer: '',
				priceList:''
            },
            loading:false,

        }
    },
    methods:{
        updateAps(link){
            this.publishing.aps = link
        },
		updatePriceList(link){
            this.publishing.priceList = link
        },
        cancel(){
            this.$message.success('Settings successfully reverted!')
            this.publishing = JSON.parse(JSON.stringify(this.original))
        },
        save(){
            console.log('THIS PUBLISHING', this.publishing)
            this.loading = true
            this.$api.put(`/settings/:instance/models`, {options:{publishing:this.publishing}}).then(({data}) => {
                this.$store.commit('UPDATE_SETTINGS', data)
                this.loading = false
                this.$message.success('Settings successfully saved!')
            }).catch(err => {
				this.loading = false
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
        }
    },
    created(){
        console.log('HEY OPENED')
        if (this.$store.state.models.allSettings.app && this.$store.state.models.allSettings.app.options && this.$store.state.models.allSettings.app.options.publishing && typeof this.$store.state.models.allSettings.app.options.publishing === 'object'){
            this.publishing = JSON.parse(JSON.stringify(this.$store.state.models.allSettings.app.options.publishing))
        }
    }
}
</script>

<style>
.cancel-button.ant-btn {
    border-color:#ECE9F1 !important;
    background-color:#ECE9F1;
    color:#3F3356
}
</style>
