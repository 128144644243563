<template>
	<div class="w-full dF fC f1 px-4 pb-4 hide-scrollbar" style="background-color: #F7F5F9; overflow: scroll;">
		<CustomFieldDrawer />
		<h4 class="mb-3">Settings</h4>
		<SettingsTab @menuClick="onTabChange" :menuList="menuList" :activeTab="activeTab" width="180">
			<PublishingAssetsSettings v-if="activeTab === 'publishing'" />
			<CustomFieldsSettings v-else-if="activeTab === 'customFields'" />
			<ManageData v-else-if="activeTab === 'manageData'" />
		</SettingsTab>
	</div>
</template>

<script>

import SettingsTab from 'bh-mod/components/common/SettingsTab'
import CustomFieldsSettings from '@/components/common/CustomFieldsSettings'
import CustomFieldDrawer from '@/components/models/CustomFieldDrawer'
import PublishingAssetsSettings from '@/components/common/PublishingAssetsSettings'
import ManageData from '@/components/common/ManageData'
export default {
	components: {
		SettingsTab, CustomFieldsSettings, PublishingAssetsSettings, CustomFieldDrawer, ManageData
	},
	data() {
		return {
			activeTab: 'publishing',
			menuList: [
				{
					label: 'General Settings', id: 'sub1', children: [{ label: 'Publishing Assets', id: 'publishing' }]
				},
				{
					label: 'Customization', id: 'sub3', children: [{ label: 'Custom Fields', id: 'customFields' }],
					info: 'Warning: If you delete any customizations, you will lose all the data associated with it inside your models'
				},
				{
					label: 'Data Management', id: 'sub2', children: [{ label: 'Manage Data', id: 'manageData' }]
				},
			],
			fieldDrawer: {
				visible: false,
				type: 'add'
			},
		}
	},
	methods: {
		onTabChange(e) {
			this.activeTab = e
		},
	},
	created() {
		this.$store.commit('changeType', 'settings')
		this.$store.commit('SET_CRUMBS', ['Settings'])
	}
}
</script>

<style>

</style>

